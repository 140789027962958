import { Answer } from './Answer.js';

export const terms = [
    {
        id: 50,
        hash: '2015_19',
        start_date: new Date('2015-12-03'),
        end_date: new Date('2019-12-01'),
        parties: [
            { id: 0, name: 'SP' },
            { id: 1, name: 'GP' },
            { id: 2, name: 'GLP' },
            { id: 3, name: 'CVP' },
            { id: 4, name: 'FDP' },
            { id: 5, name: 'SVP' },
        ],
        subjects: [
            {
                id: 628,
                date: new Date('2019-05-19'),
                name: 'EU-Waffenrichtlinie',
                hash: '2019-05_628',
                outcome: Answer.Yes,
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 627,
                date: new Date('2019-05-19'),
                name: 'Steuerreform und AHV (STAF)',
                hash: '2019-05_627',
                outcome: Answer.Yes,
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Abstention },
                ],
            },
            {
                id: 626,
                date: new Date('2019-02-10'),
                name: 'Zersiedlungsinitiative',
                hash: '2019-02_626',
                outcome: Answer.No,
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 625,
                date: new Date('2018-11-25'),
                name: 'Überwachung von Versicherten',
                hash: '2018-11_625',
                outcome: Answer.Yes,
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 624,
                date: new Date('2018-11-25'),
                name: 'Selbstbestimmungsinitiative',
                hash: '2018-11_624',
                outcome: Answer.No,
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 623,
                date: new Date('2018-11-25'),
                name: 'Hornkuh-Initiative',
                hash: '2018-11_623',
                outcome: Answer.No,
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
        ],
    },
    {
        id: 51,
        hash: '2019_23',
        start_date: new Date('2019-12-02'),
        end_date: new Date('2023-12-03'),
        parties: [
            { id: 0, name: 'SP' },
            { id: 1, name: 'GP' },
            { id: 2, name: 'GLP' },
            { id: 3, name: 'CVP' },
            { id: 4, name: 'FDP' },
            { id: 5, name: 'SVP' },
        ],
        subjects: [
            {
                id: 664,
                date: new Date('2023-06-18'),
                name: 'Covid-19-Gesetz #3',
                hash: '2023-06_664',
                outcome: Answer.Yes,
                categories: [
                    ['Sozialpolitik', 'Gesundheit'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id: 'EVP', answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id: 'EDU', answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 663,
                date: new Date('2023-06-18'),
                name: 'Klimagesetz',
                hash: '2023-06_663',
                outcome: Answer.Yes,
                categories: [
                    [
                        'Umwelt und Lebensraum',
                        'Umwelt',
                        'Umweltpolitik Energie',
                    ],
                    ['Wirtschaft', 'Wirtschaftspolitik', 'Strukturpolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id: 'EVP', answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id: 'EDU', answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 662,
                date: new Date('2023-06-18'),
                name: 'OECD-Mindeststeuer für Unternehmen',
                hash: '2023-06_662',
                outcome: Answer.Yes,
                //  	Öffentliche Finanzen > Steuerwesen > Direkte Steuern
                // Wirtschaft > Wirtschaftspolitik
                categories: [
                    ['Öffentliche Finanzen', 'Steuerwesen', 'Direkte Steuern'],
                    ['Wirtschaft', 'Wirtschaftspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.Abstention },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id: 'EVP', answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id: 'EDU', answer: Answer.Absention },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 661,
                date: new Date('2022-09-25'),
                name: 'Verrechnungssteuer',
                hash: '2022-09_661',
                outcome: Answer.No,
                // Öffentliche Finanzen > Steuerwesen > Direkte Steuern
                // Wirtschaft > Finanzwesen > Banken, Börsen, Versicherungen
                categories: [
                    // "Steuerwesen",
                    // "Finanzwesen",
                    ['Öffentliche Finanzen', 'Steuerwesen', 'Direkte Steuern'],
                    [
                        'Wirtschaft',
                        'Finanzwesen',
                        'Banken, Börsen, Versicherungen',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 660,
                date: new Date('2022-09-25'),
                name: 'Stabilisierung AHV (Rentenalter)',
                hash: '2022-09_660',
                outcome: Answer.Yes,
                // Sozialpolitik > Sozialversicherungen > Alters- und Hinterbliebenenversicherung
                // Sozialpolitik > Soziale Gruppen > Stellung der Frau
                categories: [
                    // "Soziale Gruppen",
                    // "Sozialversicherungen",
                    [
                        'Sozialpolitik',
                        'Sozialversicherungen',
                        'Alters- und Hinterbliebenenversicherung',
                    ],
                    ['Sozialpolitik', 'Soziale Gruppen', 'Stellung der Frau'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 659,
                date: new Date('2022-09-25'),
                name: 'Stabilisierung AHV (MWST)',
                hash: '2022-09_659',
                outcome: Answer.Yes,
                // Sozialpolitik > Sozialversicherungen > Alters- und Hinterbliebenenversicherung
                // Öffentliche Finanzen > Steuerwesen > Indirekte Steuern
                categories: [
                    // "Sozialversicherungen",
                    // "Steuerwesen",
                    [
                        'Sozialpolitik',
                        'Sozialversicherungen',
                        'Alters- und Hinterbliebenenversicherung',
                    ],
                    [
                        'Öffentliche Finanzen',
                        'Steuerwesen',
                        'Indirekte Steuern',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 658,
                date: new Date('2022-09-25'),
                name: 'Massentierhaltungsinitiative',
                hash: '2022-09_658',
                outcome: Answer.No,
                // Landwirtschaft > Tierische Produktion
                // Umwelt und Lebensraum > Umwelt > Tierschutz
                categories: [
                    // "Landwirtschaft",
                    // "Umwelt und Lebensraum",
                    ['Landwirtschaft', 'Tierische Produktion'],
                    ['Umwelt und Lebensraum', 'Umwelt', 'Tierschutz'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Abstention },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 657,
                date: new Date('2022-05-15'),
                name: 'Frontex EU-Verordnung',
                hash: '2022-05_657',
                outcome: Answer.Yes,
                // Aussenpolitik > Europapolitik > EU
                // Sozialpolitik > Soziale Gruppen > Flüchtlinge
                categories: [
                    // "Aussenpolitik",
                    // "Soziale Gruppen",
                    ['Aussenpolitik', 'Europapolitik', 'EU'],
                    ['Sozialpolitik', 'Soziale Gruppen', 'Flüchtlinge'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 656,
                date: new Date('2022-05-15'),
                name: 'Widerspruchsregelung bei der Organspende',
                hash: '2022-05_656',
                outcome: Answer.Yes,
                // Sozialpolitik > Gesundheit > Gesundheitspolitik
                // Staatsordnung > Rechtsordnung > Grundrechte
                categories: [
                    // "Gesundheit",
                    // "Staatsordnung" // top-level, usure
                    ['Sozialpolitik', 'Gesundheit', 'Gesundheitspolitik'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 655,
                date: new Date('2022-05-15'),
                name: 'Änderung des Filmgesetzes',
                hash: '2022-05_655',
                outcome: Answer.Yes,
                // Kultur, Religion, Medien > Kulturpolitik
                // Kultur, Religion, Medien > Medien und Kommunikation > Radio, Fernsehen, Elektronische Medien
                // Wirtschaft > Wirtschaftspolitik > Strukturpolitik
                categories: [
                    // "Kulturpolitik",
                    // "Medien und Kommunikation",
                    // "Wirtschaftspolitik"
                    ['Kultur, Religion, Medien', 'Kulturpolitik'],
                    [
                        'Kultur, Religion, Medien',
                        'Medien und Kommunikation',
                        'Radio, Fernsehen, Elektronische Medien',
                    ],
                    ['Wirtschaft', 'Wirtschaftspolitik', 'Strukturpolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 654,
                date: new Date('2022-02-13'),
                name: 'Massnahmenpaket zugunsten der Medien',
                hash: '2022-02_654',
                outcome: Answer.No,
                // Kultur, Religion, Medien > Medien und Kommunikation
                // Wirtschaft > Wirtschaftspolitik > Strukturpolitik
                categories: [
                    // "Medien und Kommunikation",
                    // "Wirtschaftspolitik",
                    ['Kultur, Religion, Medien', 'Medien und Kommunikation'],
                    ['Wirtschaft', 'Wirtschaftspolitik', 'Strukturpolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 653,
                date: new Date('2022-02-13'),
                name: 'Änderung Bundesgesetzes Stempelabgaben',
                hash: '2022-02_653',
                outcome: Answer.No,
                // Öffentliche Finanzen > Steuerwesen > Indirekte Steuern
                // Wirtschaft > Finanzwesen > Banken, Börsen, Versicherungen
                categories: [
                    [
                        'Öffentliche Finanzen',
                        'Steuerwesen',
                        'Indirekte Steuern',
                    ],
                    [
                        'Wirtschaft',
                        'Finanzwesen',
                        'Banken, Börsen, Versicherungen',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 652,
                date: new Date('2022-02-13'),
                name: 'Kinder ohne Tabakwerbung',
                hash: '2022-02_652',
                outcome: Answer.Yes,
                // Sozialpolitik > Gesundheit > Suchtmittel
                // Sozialpolitik > Soziale Gruppen > Kinder und Jugendliche
                // Wirtschaft > Wirtschaftspolitik > Wettbewerbspolitik
                categories: [
                    // "Gesundheit",
                    // "Soziale Gruppen",
                    // "Wirtschaftspolitik"
                    ['Sozialpolitik', 'Gesundheit', 'Suchtmittel'],
                    [
                        'Sozialpolitik',
                        'Soziale Gruppen',
                        'Kinder und Jugendliche',
                    ],
                    ['Wirtschaft', 'Wirtschaftspolitik', 'Wettbewerbspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 651,
                date: new Date('2022-02-13'),
                name: 'Tierversuchsverbotsinitiative',
                hash: '2022-02_651',
                outcome: Answer.No,
                // Bildung und Forschung > Forschung > Tierversuche
                // Sozialpolitik > Gesundheit > Medizinforschung und -technik
                // Sozialpolitik > Gesundheit > Medikamente
                categories: [
                    // "Bildung und Forschung",  // top level
                    // "Gesundheit",
                    ['Bildung und Forschung', 'Forschung', 'Tierversuche'],
                    [
                        'Sozialpolitik',
                        'Gesundheit',
                        'Medizinforschung und -technik',
                    ],
                    ['Sozialpolitik', 'Gesundheit', 'Medikamente'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 650,
                date: new Date('2021-11-28'),
                name: 'Covid-19-Gesetz #2',
                hash: '2021-11_650',
                outcome: Answer.Yes,
                // Sozialpolitik > Gesundheit
                // Staatsordnung > Rechtsordnung > Grundrechte
                // Wirtschaft > Wirtschaftspolitik~
                categories: [
                    // "Gesundheit",
                    // "Staatsordnung", // top level
                    // "Wirtschaftspolitik"
                    ['Sozialpolitik', 'Gesundheit'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Wirtschaft', 'Wirtschaftspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 649,
                date: new Date('2021-11-28'),
                name: 'Justiz-Initiative',
                hash: '2021-11_649',
                outcome: Answer.No,
                // Staatsordnung > Institutionen > Gerichte
                // Staatsordnung > Politisches System > Wahlsystem
                categories: [
                    // "Staatsordnung", // top level
                    ['Staatsordnung', 'Institutionen', 'Gerichte'],
                    ['Staatsordnung', 'Politisches System', 'Wahlsystem'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 648,
                date: new Date('2021-11-28'),
                name: 'Pflegeinitiative',
                hash: '2021-11_648',
                outcome: Answer.Yes,
                // Sozialpolitik > Gesundheit > Gesundheitspolitik
                // Sozialpolitik > Sozialversicherungen > Kranken- und Unfallversicherung
                // Wirtschaft > Arbeit und Beschäftigung > Arbeitsbedingungen
                categories: [
                    // "Gesundheit",
                    // "Sozialversicherungen",
                    // "Arbeit und Beschäftigung"
                    ['Sozialpolitik', 'Gesundheit', 'Gesundheitspolitik'],
                    [
                        'Sozialpolitik',
                        'Sozialversicherungen',
                        'Kranken- und Unfallversicherung',
                    ],
                    [
                        'Wirtschaft',
                        'Arbeit und Beschäftigung',
                        'Arbeitsbedingungen',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Abstention },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 647,
                date: new Date('2021-09-26'),
                name: 'Ehe für Alle',
                outcome: Answer.Yes,
                hash: '2021-09_647',
                categories: [
                    ['Sozialpolitik', 'Soziale Gruppen', 'Homosexuelle'],
                    ['Sozialpolitik', 'Soziale Gruppen', 'Familienpolitik'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 646,
                date: new Date('2021-09-26'),
                name: 'Löhne entlasten, Kapital gerecht besteuern',
                hash: '2021-09_646',
                outcome: Answer.No,
                categories: [
                    [
                        'Öffentliche Finanzen',
                        'Steuerwesen',
                        'Direkte Steuern Sozialpolitik',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 645,
                date: new Date('2021-06-13'),
                name: 'Anti-Terror-Gesetz',
                hash: '2021-06_645',
                outcome: Answer.Yes,
                categories: [
                    [
                        'Sicherheitspolitik',
                        'Öffentliche Sicherheit',
                        'Staatsschutz',
                    ],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Sicherheitspolitik', 'Öffentliche Sicherheit', 'Polizei'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Abstention },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 644,
                date: new Date('2021-06-13'),
                name: 'CO2-Gesetz',
                hash: '2021-06_644',
                outcome: Answer.No,
                categories: [
                    ['Umwelt und Lebensraum', 'Umwelt', 'Umweltpolitik'],
                    ['Energie', 'Erdöl – Gas'],
                    [
                        'Öffentliche Finanzen',
                        'Steuerwesen',
                        'Indirekte Steuern',
                    ],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 643,
                date: new Date('2021-06-13'),
                name: 'Covid-19-Gesetz #1',
                hash: '2021-06_643',
                outcome: Answer.Yes,
                categories: [
                    ['Sozialpolitik', 'Gesundheit'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Wirtschaft', 'Wirtschaftspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Abstention },
                ],
            },
            {
                id: 642,
                date: new Date('2021-06-13'),
                name: 'Pestizid-Initiative',
                hash: '2021-06_642',
                outcome: Answer.No,
                categories: [
                    ['Umwelt und Lebensraum', 'Umwelt', 'Gewässerschutz'],
                    ['Landwirtschaft', 'Agrarpolitik'],
                    ['Umwelt und Lebensraum', 'Umwelt', 'Bodenschutz'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Abstention },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 641,
                date: new Date('2021-06-13'),
                name: 'Trinkwasser-Initiative',
                hash: '2021-06_641',
                outcome: Answer.No,
                categories: [
                    ['Umwelt und Lebensraum', 'Umwelt', 'Gewässerschutz'],
                    ['Landwirtschaft', 'Agrarpolitik'],
                    ['Landwirtschaft', 'Tierische Produktion'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 640,
                date: new Date('2021-03-07'),
                name: 'Freihandelsabkommen Indonesien',
                hash: '2021-03_640',
                outcome: Answer.Yes,
                categories: [
                    ['Aussenpolitik', 'Aussenwirtschaftspolitik'],
                    ['Aussenpolitik', 'Staatsverträge mit einzelnen Staaten'],
                    ['Umwelt und Lebensraum', 'Umwelt'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 639,
                date: new Date('2021-03-07'),
                name: 'E-ID Gesetz',
                hash: '2021-03_639',
                outcome: Answer.No,
                categories: [['Staatsordnung', 'Rechtsordnung', 'Datenschutz']],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 638,
                date: new Date('2021-03-07'),
                name: 'Verhüllungsverbot',
                hash: '2021-03_638',
                outcome: Answer.Yes,
                categories: [
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Staatsordnung', 'Rechtsordnung', 'Strafrecht'],
                    ['Kultur, Religion, Medien', 'Religion – Kirchen'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Abstention },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 637,
                date: new Date('2020-11-29'),
                name: 'Kriegsgeschäfte-Initiative',
                hash: '2020-11_637',
                outcome: Answer.No,
                categories: [
                    ['Sicherheitspolitik', 'Armee', 'Rüstung'],
                    ['Staatsordnung', 'Institutionen', 'Nationalbank'],
                    ['Wirtschaft', 'Finanzwesen'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 636,
                date: new Date('2020-11-29'),
                name: 'Konzernverantwortungsinitiative',
                hash: '2020-11_636',
                outcome: Answer.No,
                categories: [
                    ['Wirtschaft', 'Wirtschaftspolitik', 'Gesellschaftsrecht'],
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Umwelt und Lebensraum', 'Umwelt'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 635,
                date: new Date('2020-09-27'),
                name: 'Kampfjetbeschaffung',
                hash: '2020-09_635',
                outcome: Answer.Yes,
                categories: [
                    ['Sicherheitspolitik', 'Armee', 'Rüstung'],
                    ['Öffentliche Finanzen'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 634,
                date: new Date('2020-09-27'),
                name: 'Vaterschaftsurlaub',
                hash: '2020-09_634',
                outcome: Answer.Yes,
                categories: [
                    ['Sozialpolitik', 'Soziale Gruppen', 'Familienpolitik'],
                    ['Sozialpolitik', 'Sozialversicherungen'],
                    ['Wirtschaft', 'Arbeit und Beschäftigung'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 633,
                date: new Date('2020-09-27'),
                name: 'Kinderzulagen',
                hash: '2020-09_633',
                outcome: Answer.No,
                categories: [
                    ['Öffentliche Finanzen', 'Steuerwesen', 'Direkte Steuern'],
                    ['Sozialpolitik', 'Soziale Gruppen', 'Familienpolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 632,
                date: new Date('2020-09-27'),
                name: 'Jagdgesetz (Wolf)',
                hash: '2020-09_632',
                outcome: Answer.No,
                categories: [
                    ['Landwirtschaft', 'Fischerei – Jagd – Haustiere'],
                    ['Umwelt und Lebensraum', 'Umwelt', 'Tierschutz'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 631,
                date: new Date('2020-09-27'),
                name: 'Begrenzungsinitiative',
                hash: '2020-09_631',
                outcome: Answer.No,
                categories: [
                    ['Aussenpolitik', 'Europapolitik', 'EU'],
                    ['Sozialpolitik', 'Soziale Gruppen', 'Ausländerpolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.Yes },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.Yes },
                ],
            },
            {
                id: 630,
                date: new Date('2020-02-09'),
                name: 'Sexuelle Diskriminierung',
                hash: '2020-02_630',
                outcome: Answer.Yes,
                categories: [
                    ['Sozialpolitik', 'Soziale Gruppen', 'Homosexuelle'],
                    ['Staatsordnung', 'Rechtsordnung', 'Strafrecht'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id:"EVP", answer: Answer.Abstention },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
            {
                id: 629,
                date: new Date('2020-02-09'),
                name: 'Mehr bezahlbare Wohnungen',
                hash: '2020-02_629',
                outcome: Answer.No,
                categories: [['Umwelt und Lebensraum', 'Wohnen', 'Mietwesen']],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id:"EVP", answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id:"EDU", answer: Answer.No },
                    // { id:"BDP", answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                ],
            },
        ],
    },
    {
        id: 52,
        hash: '2023_27',
        start_date: new Date('2023-12-04'),
        end_date: new Date('2027-12-01'), // TODO: set end_date for period 52
        parties: [
            { id: 0, name: 'SP' },
            { id: 1, name: 'GP' },
            { id: 2, name: 'GLP' },
            { id: 3, name: 'CVP' },
            { id: 4, name: 'FDP' },
            { id: 5, name: 'SVP' },
        ],
        subjects: [
            {
                id: 665,
                date: new Date('2024-03-03'),
                name: '13. AHV-Rente',
                hash: '2024-03_665',
                outcome: Answer.Yes,
                categories: [['Sozialpolitik', 'Sozialversicherungen']],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id: 'EVP', answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id: 'EDU', answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                    // { id: 'MCG', answer: Answer.No },
                ],
            },
            {
                id: 666,
                date: new Date('2024-03-03'),
                name: 'Renteninitiative',
                hash: '2024-03_666',
                outcome: Answer.No,
                categories: [['Sozialpolitik', 'Sozialversicherungen']],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id: 'EVP', answer: Answer.No },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id: 'EDU', answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                    // { id: 'MCG', answer: Answer.No },
                ],
            },
            {
                id: 667,
                date: new Date('2024-06-09'),
                name: 'Prämien-Entlastungs-Initiative',
                hash: '2024-06_667',
                outcome: Answer.No,
                categories: [
                    [
                        'Sozialpolitik',
                        'Sozialversicherungen',
                        'Kranken- und Unfallversicherung',
                    ],
                    ['Sozialpolitik', 'Gesundheit', 'Gesundheitspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id: 'EVP', answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id: 'EDU', answer: Answer.No },
                    { id: 'SVP', answer: Answer.No },
                    // { id: 'MCG', answer: Answer.Yes },
                ],
            },
            {
                id: 668,
                date: new Date('2024-06-09'),
                name: 'Kostenbremse im Gesundheitswesen',
                hash: '2024-06_668',
                outcome: Answer.No,
                categories: [
                    [
                        'Sozialpolitik',
                        'Sozialversicherungen',
                        'Kranken- und Unfallversicherung',
                    ],
                    ['Sozialpolitik', 'Gesundheit', 'Gesundheitspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id: 'EVP', answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.No },
                    // { id: 'EDU', answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.No },
                    // { id: 'MCG', answer: Answer.No },
                ],
            },
            {
                id: 669,
                date: new Date('2024-06-09'),
                name: 'Für Freiheit und körperliche Unversehrtheit',
                hash: '2024-06_669',
                outcome: Answer.No,
                categories: [
                    ['Staatsordnung', 'Rechtsordnung', 'Grundrechte'],
                    ['Sozialpolitik', 'Gesundheit', 'Gesundheitspolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.No },
                    { id: 'GP', answer: Answer.No },
                    { id: 'GLP', answer: Answer.No },
                    { id: 'CVP', answer: Answer.No },
                    // { id: 'EVP', answer: Answer.No },
                    { id: 'FDP', answer: Answer.No },
                    // { id: 'EDU', answer: Answer.Yes },
                    { id: 'SVP', answer: Answer.Yes },
                    // { id: 'MCG', answer: Answer.No },
                ],
            },
            {
                id: 670,
                date: new Date('2024-06-09'),
                name: 'Stromgesetz (Mantelerlass Energie)',
                hash: '2024-06_670',
                outcome: Answer.Yes,
                categories: [
                    ['Energie', 'Alternativenergien'],
                    [
                        'Umwelt und Lebensraum',
                        'Umwelt',
                        'Natur- und Heimatschutz',
                    ],
                    ['Energie', 'Energiepolitik'],
                ],
                parties: [
                    { id: 'SP', answer: Answer.Yes },
                    { id: 'GP', answer: Answer.Yes },
                    { id: 'GLP', answer: Answer.Yes },
                    { id: 'CVP', answer: Answer.Yes },
                    // { id: 'EVP', answer: Answer.Yes },
                    { id: 'FDP', answer: Answer.Yes },
                    // { id: 'EDU', answer: Answer.Abstention },
                    { id: 'SVP', answer: Answer.No },
                    // { id: 'MCG', answer: Answer.Yes },
                ],
            },
        ],
    },
];
